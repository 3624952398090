module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-564MJDT","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Multiplex delle Stelle","short_name":"Multiplex delle Stelle","start_url":"/","background_color":"#FFDC00","theme_color":"#FFDC00","display":"fullscreen","icon":"src/images/mds-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"821cf1ba4fff37a8161946e38dd85e63"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
